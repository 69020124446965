<template>
    <div class="page-title-component">
        <div class="back-btn" @click="goBackEvent" v-if="showBack"><!-- $router.go(-1) -->
            <i class="el-icon-back"></i>
        </div>
        <div class="info-content" :style="{flex:flex}">
            <template v-if="titleEdit">
                <div class="title" v-if="edit">
                    <el-input :placeholder="$t('component.PageTitle_input_title_placeholder')" style="width: 300px;" v-model="titleValue" size="small" @blur="saveTitleEvent" @keydown.native.enter="enterSave"></el-input>
                </div>
                <template v-else>
                    <div class="title edit" @click="editEvent">
                        <el-tooltip :content="$t('component.PageTitle_edit_title_tooltip_content')"><span>{{title}}</span></el-tooltip>
                    </div>
                </template>
            </template>
            <template v-else>
                <div class="title" v-if="title">
                    <el-tooltip placement="bottom-start" :content="title">
                        <span>{{title}}</span>
                    </el-tooltip>
                </div>
            </template>
            <slot name="info"></slot>
        </div>
        <div class="center-content">
            <slot name="center"></slot>
        </div>
        <div class="tools-content" v-if="showRight">
            <slot name="tools"></slot>
        </div>
    </div>
</template>
<script>
import $ from 'jquery';
import { debounce } from 'lodash';
export default {
    name:'PageTitle',
    props:{
        showBack:{
            type:Boolean,
            default:true
        },
        title:{
            type:String,
            default:''
        },
        titleEdit:{
            type:Boolean,
            default:false
        },
        flex:{
            type:Number,
            default:1
        },
        showRight: {
            type: Boolean,
            default: true
        },
    },
    data(){
        return {
            edit:false,
            titleValue:'',
        }
    },
    methods:{
        saveTitleEvent(){
            if(this.titleValue){
                this.$emit('saveTitle',this.titleValue);
                this.edit = false;
            }else{
                this.$message.error(this.$t('component.PageTitle_save_title_verification_message'));
            }
        },
        goBackEvent(){
            if(this._events.backEvent){
                this.$emit('backEvent');
            }else{
                this.$router.go(-1);
            }
        },
        editEvent(){
            this.edit = true;
            this.titleValue = this.title;
            this.$nextTick(() => {
                $('.page-title-component .info-content .el-input__inner').focus();
            });
        },
        enterSave(){
            if(this.titleValue){
                this.edit = false;
            }else{
                this.$message.error(this.$t('component.PageTitle_save_title_verification_message'));
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.page-title-component{
    display: flex;
    align-items: center;
    // height: 60px;
    min-height: 60px;
    box-sizing: border-box;
    border-bottom: 1px solid #0000001A;
    > .back-btn{
        width: 30px;
        height: 30px;
        font-size: 20px;
        line-height: 30px;
        cursor: pointer;
        text-align: center;
        &:hover,&:focus{
            background-color: #0000001A;
            border-radius: 4px;
        }
        > i{
            font-weight: 600;
        }
    }
    > .info-content{
        flex: 1;
        height: 30px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        > .title{
            font-weight: 600;
            font-size: 16px;
            line-height: 26px;
            color: #000000;
            margin-right: 10px;
            box-sizing: border-box;
            max-width: 300px;
            white-space:nowrap;  
            overflow:hidden;  
            text-overflow:ellipsis;
            &.edit{
                cursor: pointer;
                &:hover{
                    border-bottom: 1px solid #000000;
                }
            }
            
        }
    }
    > .center-content{
        display: flex;
        align-items: center;
    }
    > .tools-content{
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    ::v-deep .el-input__inner{
        border-radius: 20px;
        // background-color: inherit;
    }
    ::v-deep .el-input-group__append{
        cursor: pointer;
    }
}
</style>

